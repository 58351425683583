import { DatePipe } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DateTimeService } from 'src/app/services/date-time.service';

@Component({
    templateUrl: './date-range-dialog.component.html',
})
export class DateRangeDialog {
    includeTime = true;
    isEndDateChecked = false;
    maxDate = new Date();
    startDateForm = new FormControl(null, Validators.required);
    endDateForm = new FormControl(null, Validators.required);
    startTimeForm = new FormControl(null, Validators.required);
    endTimeForm = new FormControl(null, Validators.required);

    get isFormValid() {
        const formControls = [this.startDateForm];
        if (this.includeTime) {
            formControls.push(this.startTimeForm);
        }
        if (this.isEndDateChecked) {
            formControls.push(this.endDateForm);
            if (this.includeTime) {
                formControls.push(this.endTimeForm);
            }
        }
        return formControls.every((control) => control.valid);
    }

    constructor(
        public dialogRef: MatDialogRef<DateRangeDialog>,
        private datePipe: DatePipe,
        public dateTimeService: DateTimeService,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            title: string;
            startDate: Date;
            endDate: Date;
            includeTime?: boolean;
        }
    ) {
        this.startDateForm.setValue(this.data.startDate);
        this.endDateForm.setValue(this.data.endDate);
        this.includeTime = data.includeTime ?? true;
        if (this.includeTime) {
            this.startTimeForm.setValue(
                this.datePipe.transform(this.data.startDate, 'HH:mm')
            );
            this.endTimeForm.setValue(
                this.datePipe.transform(this.data.endDate, 'HH:mm')
            );
        }
        if (this.data.endDate != null) {
            this.isEndDateChecked = true;
        }
    }

    handleSave() {
        const startDate = this.startDateForm.value;
        const endDate = this.isEndDateChecked ? this.endDateForm.value : null;

        if (this.includeTime) {
            startDate.setHours(Number(this.startTimeForm.value.substr(0, 2)));
            startDate.setMinutes(Number(this.startTimeForm.value.substr(3, 5)));
            if (this.isEndDateChecked) {
                endDate.setHours(Number(this.endTimeForm.value.substr(0, 2)));
                endDate.setMinutes(Number(this.endTimeForm.value.substr(3, 5)));
            }
        }

        this.dialogRef.close({ startDate, endDate });
    }
}
