<h1 mat-dialog-title>{{ data.title | translate }}</h1>
<div mat-dialog-content class="mat-typography">
    <div class="flex flex-col items-center gap-3">
        <ng-container *ngIf="isEndDateChecked; else openEndedBlock">
            <div class="flex w-full flex-row justify-center gap-2">
                <mat-form-field appearance="fill" class="flex">
                    <mat-label>{{
                        'dialog.enter_start_date' | translate
                    }}</mat-label>
                    <input
                        matInput
                        [formControl]="startDateForm"
                        [matDatepicker]="startPicker"
                        [max]="maxDate"
                        placeholder="{{ 'dialog.start_date' | translate }}"
                    />
                    <mat-hint>{{
                        dateTimeService.dateFormat | uppercase
                    }}</mat-hint>
                    <mat-datepicker-toggle
                        matSuffix
                        [for]="startPicker"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #startPicker></mat-datepicker>
                </mat-form-field>
                <mat-form-field appearance="fill" class="flex">
                    <mat-label>{{
                        'dialog.enter_end_date' | translate
                    }}</mat-label>
                    <input
                        matInput
                        [formControl]="endDateForm"
                        [matDatepicker]="endPicker"
                        [max]="maxDate"
                        placeholder="{{ 'dialog.end_date' | translate }}"
                    />
                    <mat-hint>{{
                        dateTimeService.dateFormat | uppercase
                    }}</mat-hint>
                    <mat-datepicker-toggle
                        matSuffix
                        [for]="endPicker"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #endPicker></mat-datepicker>
                </mat-form-field>
            </div>
            <div
                *ngIf="includeTime"
                class="flex w-full flex-row justify-center gap-2"
            >
                <mat-form-field appearance="fill" class="flex w-1/2">
                    <mat-label>{{
                        'dialog.enter_start_time' | translate
                    }}</mat-label>
                    <input matInput type="time" [formControl]="startTimeForm" />
                    <mat-hint>hh:mm</mat-hint>
                </mat-form-field>
                <mat-form-field appearance="fill" class="flex w-1/2">
                    <mat-label>{{
                        'dialog.enter_end_time' | translate
                    }}</mat-label>
                    <input matInput type="time" [formControl]="endTimeForm" />
                    <mat-hint>hh:mm</mat-hint>
                </mat-form-field>
            </div>
        </ng-container>
        <ng-template #openEndedBlock>
            <mat-form-field class="w-full" appearance="fill">
                <mat-label>{{
                    'dialog.enter_start_date' | translate
                }}</mat-label>
                <input
                    matInput
                    [formControl]="startDateForm"
                    [matDatepicker]="picker"
                    [max]="maxDate"
                    placeholder="{{ 'dialog.start_date' | translate }}"
                />
                <mat-hint>{{
                    dateTimeService.dateFormat | uppercase
                }}</mat-hint>
                <mat-datepicker-toggle
                    matSuffix
                    [for]="picker"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <mat-form-field
                *ngIf="includeTime"
                class="w-full"
                appearance="fill"
            >
                <mat-label>{{
                    'dialog.enter_start_time' | translate
                }}</mat-label>
                <input matInput type="time" [formControl]="startTimeForm" />
                <mat-hint>hh:mm</mat-hint>
            </mat-form-field>
        </ng-template>
        <mat-slide-toggle color="primary" [(ngModel)]="isEndDateChecked">
            <mat-label>{{ 'dialog.end_date' | translate }}</mat-label>
        </mat-slide-toggle>
    </div>
    <sz-dialog-button-bar
        primaryText="btn.save"
        [primaryDisabled]="!isFormValid"
        (primaryClick)="handleSave()"
        (secondaryClick)="dialogRef.close()"
    />
</div>
